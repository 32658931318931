const dateListMixin = {
  data: function () {
    return {
      years: [],
      months: [],
    };
  },
  methods: {
    setYears: function () {
      const start = bo.day.getYear(bo.day.add({ date: bo.day.now(), value: 1, unit: 'year' }));
      for (let i = start; i >= 1984 ; i--) {
        this.years.push(i);
      }
    },
    setMonths: function() {
      for (let i = 12; i >= 1; i--) {
        this.months.push(i);
      }
    }
  }
};
